import classNames from 'classnames';
import React from 'react';
import {
  PinColors,
  pinColorToColorValue,
} from '../features/common/models/pin-colors';
import styles from './MaterialWarning.module.scss';
import { Error } from './icons';

interface Props {
  color: PinColors;
}

export const MaterialWarning: React.FC<Props> = ({ color }) => {
  return (
    <Error
      className={classNames(
        styles['gaas-material-warning'],
        styles[`gaas-material-warning--${pinColorToColorValue(color)}`]
      )}
    />
  );
};
