import { CircularProgress } from '@mui/material';
import React from 'react';

interface Props {
  color?: 'red' | 'grey' | 'white' | 'black';
}

export const Loading: React.FC<Props> = ({ color }) => (
  <CircularProgress
    size={20}
    role={'progressbar'}
    style={{
      color: color
        ? color === 'red'
          ? '#da0025'
          : color === 'grey'
          ? '#969696'
          : color === 'white'
          ? '#ffffff'
          : '#000000'
        : '#0078be',
    }}
  />
);
