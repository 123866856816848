import { API } from '@aws-amplify/api';
import { from, Observable } from 'rxjs';

export function apiGet<T>(
  apiName: string,
  path: string,
  params: object | undefined = undefined
): Observable<T> {
  return from(API.get(apiName, path, { queryStringParameters: params }));
}

export function apiPost<T>(
  apiName: string,
  path: string,
  data: object,
  params: object | undefined = undefined
): Observable<T> {
  return from(
    API.post(apiName, path, { body: data, queryStringParameters: params })
  );
}

export function apiPut<T>(
  apiName: string,
  path: string,
  data: object,
  params: object | undefined = undefined
): Observable<T> {
  return from(
    API.put(apiName, path, { body: data, queryStringParameters: params })
  );
}

export function apiDelete<T>(
  apiName: string,
  path: string,
  params: object | undefined = undefined,
  body: object | undefined = undefined
): Observable<T> {
  return from(API.del(apiName, path, { queryStringParameters: params, body }));
}
