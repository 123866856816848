import { Middleware } from 'redux';
import { showMessageAction } from '../features/errors/actions';
import { OverlayMessageType } from '../features/errors/models/OverlayMessage';

const getErrorFromAction = (action: any): any => {
  // Depending on the action type, the error is in a different place
  // in the payload. See for example:
  //  getInventoryAsync
  //  getContainerStateByBarcodeAsync
  if (action?.payload?.response?.data?.error) {
    return action.payload.response.data.error;
  }
  if (action?.payload?.error?.response?.data?.error) {
    return action.payload.error.response.data.error;
  }
  return {
    code: 'unknown',
    message: 'Unknown error; See technical details for more',
    criticality: 'CRITICAL',
  };
};

export const errorReporterMiddleware: Middleware = store => next => action => {
  // All our async actions are grouped into three types:
  //  *_REQUEST
  //  *_SUCCESS
  //  *_FAILURE
  // We only want to report errors, so we only handle the *_FAILURE actions
  if ((action.type as string).endsWith('_FAILURE')) {
    const error = getErrorFromAction(action);
    const type =
      error.criticality === 'CRITICAL'
        ? OverlayMessageType.Error
        : OverlayMessageType.Warning;
    // Make critical errors sticky
    const sticky = type === OverlayMessageType.Error;
    const title = error.code === 'unknown' ? 'Unknown Error' : error.code;

    store.dispatch(
      showMessageAction(
        type,
        title,
        error.message,
        sticky,
        error.code === 'unknown' ? action.payload : error
      )
    );
  }
  let result = next(action);
  return result;
};
