import React from 'react';
import styles from './Skeleton.module.scss';

interface Props {
  height?: number;
  width?: number;
}

export const Skeleton: React.FC<Props> = ({ height, width }) => (
  <div
    className={styles['gaas-skeleton']}
    style={{ height: `${height}px`, width: `${width}px` }}
  />
);
