import React from 'react';
import styles from './Checkbox.module.scss';
import { TextButton } from './TextButton';
import { CheckboxChecked, CheckboxUnchecked } from './icons';

interface Props {
  label: string;
  checked: boolean;
  onClick: () => void;
  description?: string;
  disabled?: boolean;
  title?: string;
}

export const Checkbox: React.FC<Props> = ({
  label,
  checked,
  onClick,
  description,
  disabled,
  title,
}) => {
  return (
    <div
      className={styles['gaas-checkbox']}
      role={'option'}
      aria-selected={checked}
    >
      <TextButton
        LeadingIcon={checked ? CheckboxChecked : CheckboxUnchecked}
        onClick={onClick}
        size="l"
        disabled={disabled}
        title={title}
        role={'checkbox'}
      />
      <div className={styles['gaas-checkbox--label']}>
        <span>{label}</span>
        <span className={styles['gaas-checkbox--description']}>
          {description}
        </span>
      </div>
    </div>
  );
};
