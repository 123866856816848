import React, { ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import { Button } from '../../../stories/Button';
import { Devider } from '../../../stories/Devider';
import { GatewayStatus } from '../../../stories/GatewayStatus';
import { Sidebar } from '../../../stories/Sidebar';
import { SidebarCustomerInfo } from '../../../stories/SidebarCustomerInfo';
import { TextButton } from '../../../stories/TextButton';
import { BarcodeScanner, Replay } from '../../../stories/icons';
import * as commonActions from '../actions';
import * as commonSelectors from '../selectors';
import CustomerNumberSelect from './CustomerNumberSelect';
import styles from './CustomerSelection.module.scss';
import { ApiGaasAction } from '../../../models/openapi/openapiTypes';

const mapStateToProps = (state: RootState) => ({
  customers: commonSelectors.customers(state.common),
  selectedCustomer: commonSelectors.selectedCustomer(state.common),
  gatewayStates: (customerId: string) =>
    commonSelectors.gatewayStates(customerId, state.common),
  hasPermission: (action: ApiGaasAction) =>
    commonSelectors.hasPermission(action, state.common),
});

const dispatchProps = {
  getCustomers: commonActions.getCustomersAsync.request,
  selectCustomer: commonActions.selectCustomer,
  getGatewayStates: commonActions.getGatewayStatesAsync.request,
};

type ComponentProps = {
  title: string;
  scanBottle?: boolean;
  showGatewayIndicator?: boolean;
  children: ReactElement;
  additionalSidebarContent?: ReactElement;
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof dispatchProps &
  ComponentProps;

const CustomerSelection: React.FC<Props> = ({
  title,
  scanBottle,
  customers,
  selectedCustomer,
  getCustomers,
  selectCustomer,
  gatewayStates,
  getGatewayStates,
  children,
  showGatewayIndicator,
  additionalSidebarContent,
  hasPermission,
}) => {
  let history = useHistory();

  interface RouteParams {
    customer: string;
    device: string;
  }
  let { customer: routeCustomerId } = useParams<RouteParams>();
  const location = useLocation();
  const [writeInventoryAllowed] = hasPermission('inventory.fill-level.write');

  useEffect(() => {
    if (!customers.error && !customers.loading && !customers.data) {
      getCustomers();
    }
    if (customers.data && routeCustomerId) {
      selectCustomer(
        customers.data.find(customer => customer.customerId === routeCustomerId)
      );
    }
  }, [
    customers.error,
    customers.loading,
    customers.data,
    getCustomers,
    routeCustomerId,
    selectCustomer,
  ]);

  useEffect(() => {
    if (!selectedCustomer) {
      return;
    }

    const currentStates = gatewayStates(selectedCustomer.customerId);
    if (currentStates == null) {
      getGatewayStates(selectedCustomer.customerId);
    }
  }, [gatewayStates, selectedCustomer, getGatewayStates]);

  const loadCustomers = () => {
    if (!customers.loading) {
      getCustomers();
    }
  };

  const customerSelection = () => {
    if (customers.error) {
      return (
        <>
          <p style={{ marginBottom: '1rem' }}>
            Laden der Kundendaten fehlgeschlagen.
          </p>
          <TextButton
            label="Erneut versuchen"
            LeadingIcon={Replay}
            onClick={() => loadCustomers()}
          />
        </>
      );
    }

    if (customers!.data!.length === 0) {
      return (
        <p>Es sind keine Kundendaten mit diesem Benutzerkonto verknüpft.</p>
      );
    }

    return (
      <CustomerNumberSelect
        customers={customers!.data!}
        selectedCustomer={selectedCustomer}
        onSelect={customer => {
          selectCustomer(customer);
          if (location.pathname.startsWith('/digidrumi')) {
            history.push('/digidrumi/' + customer.customerId);
          } else if (location.pathname.startsWith('/inventory')) {
            history.push('/inventory/' + customer.customerId);
          }
        }}
      />
    );
  };

  const scanBottleButton = (
    <Button
      label="Flasche scannen"
      LeadingIcon={BarcodeScanner}
      disabled={selectedCustomer?.customerId == null}
      fullWidth
    />
  );

  const scanBottleLink =
    selectedCustomer?.customerId != null && writeInventoryAllowed ? (
      // eslint-disable-next-line react/jsx-no-undef
      <Link
        to={`/inventory/inspect-bottle/${selectedCustomer?.customerId}`}
        style={{ textDecoration: 'none' }}
      >
        {scanBottleButton}
      </Link>
    ) : (
      scanBottleButton
    );

  if (customers.loading || (!customers.data && !customers.error)) {
    return (
      <div className={styles.container}>
        <Sidebar title={title} loading />

        <div className={styles.content}></div>
      </div>
    );
  }

  const gatewayIndicator = () => {
    if (selectedCustomer != null) {
      if (gatewayStates(selectedCustomer.customerId)?.loading ?? true) {
        return <GatewayStatus loading />;
      }

      const gatewaysOnline = gatewayStates(
        selectedCustomer.customerId
      ).data!.reduce((acc, val) => acc + (val.status === 'online' ? 1 : 0), 0);

      const gatewaysCount = gatewayStates(selectedCustomer.customerId).data!
        .length;

      return <GatewayStatus sum={gatewaysCount} online={gatewaysOnline} />;
    } else return;
  };

  const customerInformation =
    selectedCustomer != null ? (
      <>
        <Devider />
        <SidebarCustomerInfo
          customerId={selectedCustomer.customerId}
          customerAddress={`${selectedCustomer.street}, ${selectedCustomer.zipCode} ${selectedCustomer.city}`}
        />
        <Devider />
        {showGatewayIndicator && gatewayIndicator()}
        {scanBottle && scanBottleLink}
      </>
    ) : (
      <></>
    );

  return (
    <div className={styles.container}>
      <Sidebar title={title}>
        {customerSelection()}
        {customerInformation}
        {selectedCustomer && additionalSidebarContent}
      </Sidebar>

      {selectedCustomer && <div className={styles.content}>{children}</div>}
    </div>
  );
};

export default connect(mapStateToProps, dispatchProps)(CustomerSelection);
