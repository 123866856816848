import { Auth } from 'aws-amplify';
import { FakeAuth } from './fakeAuth';
import { AuthOptions, AwsCognitoOAuthOpts } from './fixedAwsAuthTypes';

export enum FrontendEnvironment {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
  LOCAL = 'local',
}

export const getEnvironment = (): FrontendEnvironment => {
  switch (process.env.REACT_APP_STAGE) {
    case 'local':
      return FrontendEnvironment.LOCAL;
    case 'production':
      return FrontendEnvironment.PRODUCTION;
    default:
      return FrontendEnvironment.DEVELOPMENT;
  }
};

const isProduction = getEnvironment() === FrontendEnvironment.PRODUCTION;

export const amplifyOAuthConfig: AwsCognitoOAuthOpts = isProduction
  ? {
      domain: 'gaas-salesforce-production.auth.eu-central-1.amazoncognito.com',
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: window.location.origin,
      redirectSignOut:
        'https://id.westfalen.com/services/auth/idp/oidc/logout?client_id=7efeaqu2nt0do3cvq0jeo57apa',
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code,
    }
  : {
      domain: 'gaas-salesforce.auth.eu-central-1.amazoncognito.com',
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: window.location.origin,
      redirectSignOut:
        'https://westfalen--uat.sandbox.my.salesforce.com/services/auth/idp/oidc/logout?client_id=6gpp135t0sb9th1i66iqbm1780',
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code,
    };

export const amplifyAuthConfig: AuthOptions = {
  // REQUIRED - Amazon Cognito Region
  region: 'eu-central-1',

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: isProduction
    ? 'eu-central-1_o5IuAqTAN'
    : 'eu-central-1_UuVfuSQ3I',

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: isProduction
    ? '7efeaqu2nt0do3cvq0jeo57apa'
    : '14liqvbq23qlel5dro1uc335t1',

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: true,

  // OPTIONAL - Hosted UI configuration
  oauth: amplifyOAuthConfig,
};

let endpoint = `https://cloud-api.net/gaas-${getEnvironment()}`;
if (getEnvironment() === 'local') {
  // you can use REACT_APP_API_ENDPOINT to provide a custom api endpoint
  const hostFromEnv = process.env.REACT_APP_API_ENDPOINT;
  endpoint = hostFromEnv ? `http://${hostFromEnv}` : 'http://localhost:9000';
}

let publicEndpoint = `https://cloud-api.net/gaas-misc-${process.env.REACT_APP_STAGE}`;
if (process.env.REACT_APP_STAGE === 'local') {
  publicEndpoint = endpoint;
}

const amplifyConfig = {
  Auth: amplifyAuthConfig,
  API: {
    endpoints: [
      {
        name: 'GaasBackend',
        endpoint: endpoint,
        custom_header: async () => {
          if (process.env.REACT_APP_STAGE === 'local') {
            return {
              'x-tapdo-fake-auth': JSON.stringify(FakeAuth.getCredentials()),
            };
          }
          return {
            AccessToken: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          };
        },
      },
      {
        name: 'PublicGaasBackend',
        endpoint: publicEndpoint,
      },
      {
        name: 'FirewallCheckBackend',
        endpoint: `https://cloud-api.net/firewallcheck-${
          process.env.REACT_APP_STAGE === 'development'
            ? 'staging'
            : 'production'
        }`,
      },
    ],
  },
};

export const webSocketConfig = {
  endpoint:
    getEnvironment() !== 'local'
      ? endpoint.replace('https://', 'wss://ws.')
      : (process.env.REACT_APP_WS_API_ENDPOINT
          ? process.env.REACT_APP_WS_API_ENDPOINT
          : endpoint.replace(':9000', ':8080')
        ).replace('http://', 'ws://'),
  credentials: (token: string | undefined) => {
    if (getEnvironment() === 'local') {
      const credentials = FakeAuth.getCredentials();
      if (!credentials) {
        return undefined;
      }
      return encodeURIComponent(
        JSON.stringify(FakeAuth.getCredentials())
      ).toString();
    }
    return token;
  },
};

export default amplifyConfig;
