import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';

import styles from './CustomerNumberSelect.module.scss';
import { ApiCustomer } from '../../../models/openapi/openapiTypes';

interface Props {
  customers: ApiCustomer[];
  selectedCustomer?: ApiCustomer;
  onSelect: (customer: ApiCustomer) => void;
}

const CustomerNumberSelect: React.FC<Props> = ({
  customers,
  selectedCustomer,
  onSelect,
}) => {
  return (
    <FormControl variant="outlined" className={styles.formControl}>
      <Select
        value={selectedCustomer?.customerId ?? 'DEFAULT'}
        onChange={event =>
          onSelect(customers.find(c => c.customerId === event.target.value)!)
        }
        sx={{ borderRadius: 0, fontFamily: 'WestfalenNewsSans' }}
        inputProps={{ style: { width: 100, display: 'block' } }}
      >
        <MenuItem
          disabled
          value="DEFAULT"
          sx={{ fontFamily: 'WestfalenNewsSans' }}
        >
          <strong>Bitte wählen</strong>
        </MenuItem>
        {customers.map(customer => (
          <MenuItem
            key={customer.customerId}
            value={customer.customerId}
            sx={{ fontFamily: 'WestfalenNewsSans' }}
          >
            {customer.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomerNumberSelect;
