import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import administrationReducer from '../features/administration/reducer';
import commonReducer from '../features/common/reducer';
import digidrumiReducer from '../features/digidrumi/reducer';
import { errorReducer } from '../features/errors/reducer';
import groupsReducer from '../features/groups/reducer';
import inventoryReducer from '../features/inventory/reducer';
import scannerReducer from '../features/scanning/reducer';
import webSocketReducer from '../features/websockets/reducer';

const createRootReducer = (history: History) =>
  combineReducers({
    common: commonReducer,
    digidrumi: digidrumiReducer,
    inventory: inventoryReducer,
    administration: administrationReducer,
    groups: groupsReducer,
    overlay: errorReducer,
    scanner: scannerReducer,
    websocket: webSocketReducer,
    router: connectRouter(history),
  });

export default createRootReducer;
