import { Autocomplete, Chip, OutlinedInput, TextField } from '@mui/material';
import React, { useState } from 'react';
import { PinColors } from '../features/common/models/pin-colors';
import { Checkbox } from './Checkbox';
import { LabelAndText } from './LabelAndText';
import { Modal } from './Modal';
import { PinColorSelector } from './PinColorSelector';
import styles from './PinModal.module.scss';

interface Props {
  action?: 'edit' | 'add';
  variant?: 'inventory' | 'manometer';
  open: boolean;
  onClose: () => void;
  onSave: (threshold: any) => void;
  onDelete?: () => void;
  currentName?: string;
  currentColor?: PinColors | string;
  currentValue?: number;
  portalNotification?: boolean;
  emailNotifications?: string[];
}

// for email: see https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
const EmailValidationRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gm;

export const PinModal: React.FC<Props> = ({
  action = 'add',
  variant = 'manometer',
  open,
  onClose,
  onSave,
  onDelete,
  currentName = '',
  currentColor = PinColors.red,
  currentValue = 0,
  portalNotification = false,
  emailNotifications = [],
}) => {
  const [name, setName] = useState(currentName);
  const [color, setColor] = useState<string>(currentColor);
  const [value, setValue] = useState<number>(currentValue);
  const [notification, setNotification] = useState(portalNotification);
  const [showEmail, setShowEmail] = useState(!!emailNotifications[0]);
  const [emails, setEmails] = useState<string[]>(emailNotifications);

  const [nameClicked, setNameClicked] = useState(false);
  const [valueClicked, setValueClicked] = useState(false);

  const nameOk = name.length > 0;
  const valueOk = !isNaN(value) && value >= 0;
  const emailOk =
    !showEmail ||
    (emails.every(email => email.match(EmailValidationRegex) != null) && // assert valid email
      emails.every(
        email => emails.lastIndexOf(email) === emails.indexOf(email)
      )); // assert uniqueness
  const formOk = nameOk && valueOk && emailOk;

  const onNewEmailEntered = (candidate: string, reason: 'blur' | 'input') => {
    const separators = [' ', ',', ';'];
    if (candidate.length <= 0) {
      return;
    }
    if (
      !separators.some(char => candidate.endsWith(char)) &&
      reason === 'input'
    ) {
      return;
    }

    const trimmedCandidate = candidate.replaceAll(
      new RegExp(`[${separators.join('')}]`, 'g'),
      ''
    );
    if (trimmedCandidate.length === 0) {
      return;
    }
    setEmails([...emails, trimmedCandidate]);
  };

  return (
    <Modal
      title={action === 'edit' ? 'Alarm bearbeiten' : 'Alarm hinzufügen'}
      open={open}
      onClose={onClose}
      maxWidth={440}
      onSave={{
        disabled: !formOk,
        action: () => {
          onSave({
            name: name,
            color: color,
            pressureBar: value,
            portalNotification: notification,
            emailRecipients: showEmail ? emails : [],
          });
          onClose();
        },
      }}
      onDelete={onDelete && { action: onDelete }}
    >
      <div>
        <LabelAndText label="Name" />
        <OutlinedInput
          placeholder="Alarm benennen"
          value={name}
          error={nameClicked && !nameOk}
          onChange={e => setName(e.currentTarget.value)}
          onBlur={() => setNameClicked(true)}
          fullWidth
          autoFocus
          sx={{ borderRadius: 0, fontFamily: 'WestfalenNewsSans' }}
        />
      </div>

      <div className={styles['gaas-pin-modal--colors']}>
        <PinColorSelector
          color={PinColors.green}
          onClick={() => setColor(PinColors.green)}
          selected={color === PinColors.green}
        />
        <PinColorSelector
          color={PinColors.yellow}
          onClick={() => setColor(PinColors.yellow)}
          selected={color === PinColors.yellow}
        />
        <PinColorSelector
          color={PinColors.orange}
          onClick={() => setColor(PinColors.orange)}
          selected={color === PinColors.orange}
        />
        <PinColorSelector
          color={PinColors.red}
          onClick={() => setColor(PinColors.red)}
          selected={color === PinColors.red}
        />
        <PinColorSelector
          color={PinColors.grey}
          onClick={() => setColor(PinColors.grey)}
          selected={color === PinColors.grey}
        />
      </div>

      <div>
        <LabelAndText
          label="Schwellwert"
          hint={
            variant === 'manometer'
              ? 'Die Aktionen werden ausgelöst, wenn der gemessene Druck den angegebenen Wert unterschreitet.'
              : 'Die Aktionen werden ausgelöst, wenn der Vollgutbestand im Inventar kleiner oder gleich dem definierten Schwellwert ist.'
          }
        />

        <OutlinedInput
          type="number"
          placeholder="0"
          value={isNaN(value) ? '' : value}
          error={valueClicked && !valueOk}
          onChange={e => setValue(parseInt(e.currentTarget.value))}
          onBlur={() => setValueClicked(true)}
          fullWidth
          endAdornment={
            <span style={{ fontWeight: 600, opacity: 0.5 }}>
              {variant === 'manometer' ? 'bar' : 'Flaschen'}
            </span>
          }
          sx={{ borderRadius: 0, fontFamily: 'WestfalenNewsSans' }}
        />
      </div>

      <div role={'listbox'}>
        <Checkbox
          label="Benachrichtigung im Portal"
          checked={notification}
          onClick={() => {
            console.log('CLICKED');
            setNotification(!notification);
          }}
          description="Sendet eine Benachrichtigung zur Glocke in der Menü-Leiste."
        />
        <Checkbox
          label="Benachrichtigung per E-Mail"
          checked={showEmail}
          onClick={() => setShowEmail(!showEmail)}
        />
      </div>

      {showEmail && (
        <div>
          <LabelAndText label="E-Mails an" />
          <Autocomplete
            id="email-notification-input"
            multiple
            options={[]}
            value={emails}
            selectOnFocus
            handleHomeEndKeys
            freeSolo
            clearOnBlur
            onChange={(event, newValue, reason) => {
              // @ts-ignore
              setEmails(newValue);
            }}
            renderTags={(value: readonly string[][], getTagProps) =>
              value.map((option: string[], index: number) => (
                <Chip
                  variant="outlined"
                  label={option}
                  color={
                    // @ts-ignore
                    option.match(EmailValidationRegex) != null
                      ? 'primary'
                      : 'secondary'
                  }
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="E-Mail-Adressen"
                onChange={event =>
                  onNewEmailEntered(event.target.value, 'input')
                }
                onBlur={event => onNewEmailEntered(event.target.value, 'blur')}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '0',
                  },
                }}
              />
            )}
          />
        </div>
      )}
    </Modal>
  );
};
