import { combineEpics } from 'redux-observable';
import * as administrationEpics from '../features/administration/epics';
import * as commonEpics from '../features/common/epics';
import * as digidrumiEpics from '../features/digidrumi/epics';
import * as groupEpics from '../features/groups/epics';
import * as inventoryEpics from '../features/inventory/epics';
import * as websocketEpics from '../features/websockets/epics';

const rootEpic = combineEpics(
  ...Object.values(commonEpics),
  ...Object.values(digidrumiEpics),
  ...Object.values(inventoryEpics),
  ...Object.values(administrationEpics),
  ...Object.values(groupEpics),
  ...Object.values(websocketEpics)
);

export default rootEpic;
