import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import VersionInfo from '../components/VersionInfo';
import AdministrationPage from '../features/administration/screens/AdministrationPage';
import BarcodeTestPage from '../features/administration/screens/BarcodeTestPage';
import GatewayAdministrationPage from '../features/administration/screens/GatewayAdministrationPage';
import GatewayEdit from '../features/administration/screens/GatewayEdit';
import MaintenancePage from '../features/administration/screens/MaintenancePage';
import ValveAdministrationPage from '../features/administration/screens/ValveAdministrationPage';
import ValveEdit from '../features/administration/screens/ValveEdit';
import * as commonActions from '../features/common/actions';
import { UserRole } from '../features/common/models/user-role';
import DigiDrumiBarcodePairingPage from '../features/digidrumi/screens/DigiDrumiBarcodePairingPage';
import DigiDrumiPage from '../features/digidrumi/screens/DigiDrumiPage';
import InventoryBarcodeScannerPage from '../features/inventory/screens/InventoryBarcodeScannerPage';
import InventoryBottlePage from '../features/inventory/screens/InventoryBottlePage';
import InventoryPage from '../features/inventory/screens/InventoryPage';
import GroupPage from '../features/groups/screens/GroupPage';
import DigiDrumiRedirectPage from '../features/digidrumi/screens/DigiDrumiRedirectPage';
import BarcodeTestPageQuaggaBase from '../features/administration/screens/BarcodeTestPageQuaggaBase';

const mapStateToProps = (state: RootState) => ({
  user: state.common.user,
});

const dispatchProps = {
  signOut: commonActions.signOutAsync.request,
};

type Props = ReturnType<typeof mapStateToProps> & typeof dispatchProps;

const AuthenticatedRoutes: React.FC<Props> = ({ signOut, user }) => {
  return (
    <Switch>
      <Route exact path="/" render={() => <Redirect to="/inventory/" />} />
      <Route exact path="/inventory/" component={InventoryPage} />
      <Route
        exact
        path="/inventory/inspect-bottle/:customer"
        component={InventoryBarcodeScannerPage}
      />
      <Route
        exact
        path="/inventory/:customer/:material?"
        component={InventoryPage}
      />
      <Route
        exact
        path="/inventory/inspect-bottle/:customer/:barcode"
        component={InventoryBottlePage}
      />
      <Route
        exact
        path="/public/inspect-bottle/:barcode"
        component={InventoryBottlePage}
      />
      <Route exact path="/digidrumi/" component={DigiDrumiPage} />
      <Route
        exact
        path="/digidrumi/pair-container/:customer/:serialNumber"
        component={DigiDrumiBarcodePairingPage}
      />
      <Route
        path="/digidrumi/inspect-meter/:serialNumber?"
        component={DigiDrumiRedirectPage}
      />
      <Route
        path="/digidrumi/:customer/:serialNumber?"
        component={DigiDrumiPage}
      />

      <Route
        exact
        path="/signout/"
        render={() => {
          signOut();
          return null;
        }}
      />
      <Route exact path="/groups/" component={GroupPage} />
      <Route path="/groups/:customer/:group?" component={GroupPage} />

      <Route exact path="/version/" component={VersionInfo} />
      {user?.role === UserRole.Admin && (
        <>
          <Route exact path="/admin/" component={AdministrationPage} />
          <Route
            exact
            path="/admin/valves"
            component={ValveAdministrationPage}
          />
          <Route
            exact
            path="/admin/edit-valve/:valveId"
            component={ValveEdit}
          />
          <Route
            exact
            path="/admin/gateways"
            component={GatewayAdministrationPage}
          />
          <Route
            exact
            path="/admin/edit-gateway/:gatewayId"
            component={GatewayEdit}
          />
          <Route
            exact
            path="/admin/barcode-scanner"
            component={BarcodeTestPage}
          />
          <Route
            exact
            path="/admin/barcode-scanner-quagga-base"
            component={BarcodeTestPageQuaggaBase}
          />
          <Route exact path="/admin/maintenance" component={MaintenancePage} />
        </>
      )}
    </Switch>
  );
};

export default connect(mapStateToProps, dispatchProps)(AuthenticatedRoutes);
