import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './Button.module.scss';
import { Loading } from './Loading';
import { PlansChip } from './PlansChip';
import { PlansModal } from './PlansModal';
import { PermissionQueryResponse } from '../features/common/models/permission-query-response';

interface Props {
  label?: string;
  variant?: 'primary' | 'outlined';
  size?: 's' | 'm' | 'l';
  fullWidth?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  LeadingIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  TrailingIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  buttonRef?: React.ForwardedRef<HTMLButtonElement>;
  loading?: boolean;
  permission?: PermissionQueryResponse;
}

export const Button = ({
  variant = 'primary',
  size = 'm',
  label,
  fullWidth,
  LeadingIcon,
  TrailingIcon,
  buttonRef,
  loading,
  permission,
  ...props
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const Content = () => (
    <>
      {LeadingIcon && <LeadingIcon className={styles['gaas-button--icon']} />}
      {label && label}
      {TrailingIcon && <TrailingIcon className={styles['gaas-button--icon']} />}
    </>
  );

  if (permission && permission?.[0] !== 'GRANTED') {
    return (
      <>
        <button
          type="button"
          className={classNames(
            styles['gaas-button'],
            styles[`gaas-button--${size}`],
            styles['gaas-button--permission'],
            {
              [styles['gaas-button--fullwidth']]: fullWidth,
            }
          )}
          ref={buttonRef}
          onClick={() => setModalOpen(true)}
        >
          <PlansChip permission={permission} top={-16} right={8} />
          <Content />
        </button>

        <PlansModal
          open={modalOpen}
          onClose={() => setModalOpen(false)}
          permission={permission}
        />
      </>
    );
  } else
    return (
      <button
        type="button"
        className={classNames(
          styles['gaas-button'],
          styles[`gaas-button--${variant}`],
          styles[`gaas-button--${size}`],
          {
            [styles['gaas-button--fullwidth']]: fullWidth,
          }
        )}
        ref={buttonRef}
        {...props}
      >
        {loading ? (
          <Loading color={variant === 'outlined' ? 'black' : 'white'} />
        ) : (
          <Content />
        )}
      </button>
    );
};
